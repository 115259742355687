<template>
  <div v-if="versionError.length || visibleMessages.length">
    <ion-row class="warning-version" v-if="versionError.length">
      <ion-col>
        <i class="fas fa-exclamation-triangle"></i> {{ versionError }}
      </ion-col>
      <ion-col size="3">
        <ion-button href @click="update" color="dark" size="small">Mettre à jour</ion-button>
      </ion-col>
    </ion-row>
    <ion-row class="service-message" v-for="item of visibleMessages" :key="item.id">
      <ion-col>
        <i class="fa fa-bell"></i> {{ versionError }}
        {{ item.message }}
      </ion-col>
      <ion-col size="3" class="text-right">
        <ion-button color="dark" href @click.prevent="hideMessage(item)">
          X
        </ion-button>
      </ion-col>
    </ion-row>
  </div>
</template>

<script>
import {IonCol, IonRow, IonButton} from "@ionic/vue"
import {BackendMixin} from "@/mixins/backend"
import { BackendApi } from '../utils/http'
import {mapActions} from "vuex"

export default {
  name: "WarningVersion",
  mixins: [BackendMixin],
  components: {
    IonRow, IonCol, IonButton,
  },
  data() {
    return {
      versionError: '',
      version: process.env.VUE_APP_VERSION,
      messages: [],
    }
  },
  mounted() {
    this.versionVerification()
    this.verificationCycle()
  },
  computed: {
    visibleMessages() {
      const messages = []
      for (const item of this.messages) {
        if (!localStorage.getItem('hidden-message-' + item.id)) {
          messages.push(item)
        }
      }
      return messages
    },
  },
  methods: {
    ...mapActions(['addError']),

    async getBackendVersion() {
      const backendApi = new BackendApi('get', '/api/backends/service-checks/?family=1')
      try {
        const resp = await backendApi.callApi()
        const backendVersion = resp.data.version
        if (this.version !== backendVersion) {
          this.versionError = 'Une nouvelle version de millibase.net est disponible'
        }
        this.messages = resp.data.messages
      } catch (err) {
        await this.addWarning(this.getErrorText(err))
      }
    },
    async versionVerification() {
      await this.getBackendVersion()
    },
    verificationCycle() {
      const delayInSec = 15 * 60 // 15 minutes
      setInterval(this.versionVerification, delayInSec * 1000)
    },
    update() {
      window.location.reload()
    },
    hideMessage(item) {
      localStorage.setItem('hidden-message-' + item.id, '1')
      this.messages = [].concat(this.visibleMessages)
    },
  },
}
</script>

<style scoped>
.warning-version {
  background-color: #ffca22;
  padding: 3px;
  position: relative;
  z-index: 1;
}
.service-message {
  font-size: 14px;
  background-color: var(--ion-color-medium);
  color: var(--ion-color-medium-contrast);
  padding: 3px;
  position: relative;
  z-index: 1;
}
ion-button {
  --border-color: #000;
  --border-style: solid;
  --border-width: 1px;
  /*text-transform: none;*/
}
</style>