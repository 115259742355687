
import { IonTabBar, IonTabButton, IonTabs, IonLabel, IonPage, IonHeader, IonToolbar, IonContent } from '@ionic/vue'
import store from '@/store'
import { computed, ref, watchEffect } from 'vue'
import { loadCertificateRequests } from '@/apis/certificates'
import { CertificateRequest } from '@/types/certificates'
import { loadUser } from '@/utils/auth'
import { checkBackend } from '@/apis/settings'
import NewPortalRedirect from '@/components/NewPortalRedirect.vue'
import TopBar from '@/components/TopBar.vue'
import WarningVersion from '@/components/WarningVersion.vue'

export default {
  name: 'Tabs',
  components: {
    IonContent,
    WarningVersion,
    TopBar, IonToolbar, IonHeader, NewPortalRedirect, IonLabel, IonTabs, IonTabBar, IonTabButton, IonPage, },
  setup() {
    const newPortalUrl = ref('')
    const certificateRequests = computed(() => {
      return store.getters.certificateRequests
    })

    const selectedEntity = computed(() => { return store.getters.selectedEntity })

    async function checkNewPortal() {
      const user = loadUser()
      newPortalUrl.value = ''
      if (user && user.backend) {
        const newPortalV2 = await checkBackend(user.backend.url)
        newPortalUrl.value = newPortalV2
      }
    }
    checkNewPortal()

    watchEffect(
      async function() {
        if (selectedEntity.value) {
          const elements: CertificateRequest[] = await loadCertificateRequests(selectedEntity.value)
          store.commit('setCertificateRequests', elements)
        }
      }
    )

    const certificatesToDoCount = computed(() => {
      return store.getters.certificateRequests.filter(
        (elt: CertificateRequest) => {
          return (
              elt.certificateType.isRequired &&
              (
                  (elt.certificate === null) ||
                  (elt.certificate.refused)
              )
          )
        }
      ).length
    })
    return { certificateRequests, certificatesToDoCount, newPortalUrl, }
  },
}
