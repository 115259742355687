import { Entity } from '@/types/people'
import { CertificateRequest, makeCertificateRequest } from '@/types/certificates'
import { BackendApi } from '@/utils/http'

export function loadCertificateRequests(entity: Entity): Promise<CertificateRequest[]> {
  return new Promise(
    (resolve, reject) => {
      const url = '/portal/api/entity/' + entity.id + '/certificates/'
      const backendApi = new BackendApi('get', url)
      backendApi.callApi().then(
        (resp) => {
          resolve(resp.data.map((elt: any) => makeCertificateRequest(elt)))
        }
      ).catch(err => {
        reject(err)
      })
    }
  )
}