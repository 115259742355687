<template>
  <div>
    <ion-card>
      <ion-card-header color="primary">
        <b>
          <i class="fa fa-info-circle"></i>
          L'adresse de votre portail Familles a changé
        </b>
      </ion-card-header>
      <ion-card-content style="padding: 20px;">
        <b>Connectez-vous sur: <a :href="backendUrl">{{ backendUrl }}</a></b>
      </ion-card-content>
    </ion-card>
  </div>
</template>

<script>
import { IonCard, IonCardContent, IonCardHeader } from "@ionic/vue"

export default {
  name: "NewPortalRedirect",
  components: { IonCardHeader, IonCardContent, IonCard, },
  props: {
    backendUrl: String,
  },
  data() {
    return {
    }
  },
}
</script>

<style scoped>
</style>